<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="page-content">
    <!-- todo search company name & pagination -->
    <div class="mx-1">
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <h3>User Activities</h3>
            <div class="row d-flex justify-content-between align-items-center pt-3">
              <div class="col-6 col-sm-6">
                <form class="search-form" @submit.prevent="doSearch()">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <input v-model="search.search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                  </div>
                </form>
              </div>
              <div class="col-6 col-sm-6">
                <el-select class="w-100" id="company_id" @change="doSearch()"
                  filterable
                  :filter-method="handlerSearchCompany" v-model="search.company_id">
                  <el-option key="" label="All Company" value=""></el-option>
                  <el-option v-for="cp in company_list" :key="cp.id" :label="cp.name" :value="cp.id"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Time</th>
                    <th>User</th>
                    <th>Feature</th>
                    <th>Activity</th>
                    <th>description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in userActivities" :key="item.id">
                    <td>{{ item.company_name }}</td>
                    <td>{{ item.created_at_str }}</td>
                    <td>{{ item.user_name }}</td>
                    <td>{{ item.feature }}</td>
                    <td>{{ item.activity }}</td>
                    <td>{{ item.description }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>

      <el-pagination
      :page-size="10"
      layout="prev, pager, next"
      :total="this.totalRow"
      @current-change="setPage"
      >

      </el-pagination>
    </div>
  </div>
</template>
<style>
#btn_link {
  background:none;border: none;color: #1e5b2a;
}
#btn_link:hover {
  text-decoration: underline;
  color: #143d1c;
}
.btn-search {
  margin-left: 1em;
}
.pageBox {
  background-color: #1e5b2a;
  color: white;
  padding: 0.5em 1em;
  margin: 0.5em 1em;
  cursor: pointer;
  border: 0;
}
.pageBox:hover {
  color: white;
}
</style>
<script>

import moment from 'moment';
import logAPI from '../../api/log';
// import popupErrorMessages from '../../library/popup-error-messages';

export default {
  beforeCreate() {
    this.moment = moment;
  },
  name: 'UserActivities',
  metaInfo: {
    title: 'User Activities',
  },
  data() {
    return {
      loading: {
        table: false,
      },
      userActivities: [],
      companyName: '',
      dataSize: 0,
      dataTotal: 0,
      search: {
        search: this.$route.query.search || '',
        company_id: '',
        page: this.$route.query.page || 1,
        per_page: this.$route.query.per_page || 10,
      },
      page: 1,
      totalRow: 0,
      company_list: [],
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
  },
  mounted() {
    this.getCompanies();
    this.getList();
  },
  methods: {
    doSearch() {
      this.page = 1;
      this.search.page = 1;
      this.search.per_page = 10;
      this.getList();
    },
    setPage(val) {
      this.search.page = val;
      this.page = val;
      this.getList();
    },
    handlerSearchCompany(value) {
      clearTimeout(this.timeout_search_user);
      this.timeout_search_user = setTimeout(() => {
        this.getCompanies('company_name', value);
      }, 1000);
    },
    async getList() {
      const loader = this.$loading.show();
      this.loading.table = true;
      await logAPI.getList(this.search.page, this.search.search, this.search.company_id)
        .then((res) => {
          this.totalRow = res.data.data.count;
          this.userActivities = res.data.data.rows.map((act) => {
            const data = act;
            data.company_name = data.company ? data.company.name : '-';
            ['created_at'].forEach((v) => {
              data[`${v}_str`] = act[v] ? moment(act[v]).format('DD MMM YYYY, HH:mm') : '-';
            });
            return data;
          });
        });
      this.loading.table = false;
      loader.hide();
    },
    async getCompanies(search_by = '', search = '') {
      await logAPI.getCompanies(1, search_by, search)
        .then(async (res) => {
          // await popupErrorMessages(res);
          this.company_list = res.data.data.rows;
        });
    },
  },
};
</script>
